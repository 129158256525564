.app-header {
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background: #023353;
  padding: 10px 0px;
}

.app-header-logo-wrapper {
  box-shadow: 1px 1px 4px 0px rgba(0,0,0,0.2);
  border-radius: 5px;
  width: fit-content;
  position: absolute;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  top: 50px;
  left: 15px;
  max-width: 100px;
  min-height: 80px;
}

.app-header-logo {
  width: 100%;
}
