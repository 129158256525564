.start-ups-container {
  width: 100%;
}

.title {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 24px;
  color: #023353;
}

.back-icon {
  cursor: pointer;
  margin: 0 auto 0 10px;
}

.start-up-details {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 15px;
}

.start-up-wrapper {
  height: 100px;
  display: flex;
  align-items: center;
  padding: 10px 15px;
  cursor: pointer;
}

.start-up-logo {
  width: 100%;
  padding: 10px;
}

.detailed-page-section {
  padding: 20px;
  margin-top: 40px;
}

.sub-title {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 15px;
  color: #023353;
  margin-bottom: 15px;
}

.detailed-text {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: #5c5c5c;
}

.link-text {
  display: flex;
  gap: 10px;
  align-items: center;
  margin: 15px 0;
}

.link-url {
  text-decoration: none;
  color: #5c5c5c;
}

.content-section {
  margin-bottom: 10px;
}

.collaborators-section {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  margin-bottom: 10px;
  padding: 5px 15px 0px;
}

.member-list,
.price-details,
.contact-text,
.about-text {
  padding: 5px 15px 0px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 15px;
  color: #023353;
}

.laptop-image-wrapper {
  padding: 15px 5px;
}

.product-service-image {
  width: 100%;
}

.event-icon {
  max-width: 100%;
}

.connect-button {
  height: 40px;
  width: 100%;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin: 30px 0;
  cursor: pointer;
  background: #fff;
  background-color: #023353;
  border: 0;
}

.connect-button-link {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 700;
  text-decoration: none;
  color: #fff;
  display: inline-block;
  width: 100%;
  line-height: 40px;
}
