.feedback-details-container {
  width: 100%;
}

.feedback-details-title-container .feedback-details-title {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 130%;
  letter-spacing: 0.25px;
  color: #fff;
  text-align: center;
  width: 85%;
}

.feedback-details-container .feedback-boxes-container {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.feedback-details-container .feedback-boxes-wrapper {
  width: 100%;
  text-decoration: none;
}

.feedback-details-title-container {
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background: #023353;
}
