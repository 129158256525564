.feedback-details-box {
  width: 100%;
  background: #ffffff;
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding: 20px;
  display: flex;
  position: relative;
}

.feedback-details-box .feedback-details-box-content {
  width: 90%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.feedback-details-box .feedback-box-date-time {
  position: absolute;
  top: 20px;
  right: 20px;
}

.feedback-details-box-content {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 130%;
  letter-spacing: 0.25px;
}

.feedback-details-box-content .feedback-box-content-title {
  color: #023353;
}

.feedback-details-box-content .feedback-box-content-value {
  color: #5c5c5c;
}

.feedback-box-date-time .feedback-details-box-date,
.feedback-box-date-time .feedback-details-box-time {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 130%;
  letter-spacing: 0.25px;
  color: #5c5c5c;
}
