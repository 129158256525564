.footer-section {
  width: 100%;
  height: 40px;
  background-color: #023353;
  text-align: center;
  display: flex;
  justify-content: center;
}

.footer-icons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
