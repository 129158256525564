.side-menu-wrapper {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  display: flex;
}

.side-menu {
  height: 100%;
  width: 75%;
  background-color: #fff;
  overflow-x: hidden;
  transition: 0.5s;
  border: 1px solid #ececec;
  position: relative;
}

.side-menu-overlay {
  width: 30%;
  background-color: #000;
  opacity: 0.5;
}
.menu-close-button {
  position: absolute;
  top: 20px;
  right: 15px;
  cursor: pointer;
}

.side-menu-link .side-menu-link-text {
  text-decoration: none;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 19px;
  line-height: 25px;
  color: #023353;
  cursor: pointer;
}

.side-menu-link {
  text-align: left;
  padding: 10px 20px;
}

.side-menu-links {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 100px 0 35px 10px;
}

.main-logos {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  flex-direction: column;
  width: 40px;
  position: absolute;
  top: 20px;
  right: 20px;
  max-height: 100px;
}

.main-logo,
.anac-logo {
  width: 40px;
  height: auto;
}
