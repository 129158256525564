.start-up-register-form-root {
  margin: 0 auto;
  width: 100%;
}

.form-container {
  padding: 20px;
}

.form-input--textarea {
  padding: 10px;
}

.image-upload-dialog {
  display: none;
}

.register-image-upload {
  width: 2rem; /* 32ppx */
  height: 1.25rem; /* 20px */
  cursor: pointer;
}

.img-upload-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.uploaded-img {
  max-height: 30px;
  cursor: pointer;
}

.uploaded-imgs-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
}
