.program-container {
  padding: 80px 40px 100px 40px;
  display: flex;
  flex-direction: column;
  text-align: center;
  overflow: auto;
  gap: 30px;
  position: relative;
}

.hamburger-menu {
  position: absolute;
  top: 30px;
  left: 30px;
  cursor: pointer;
  z-index: 100;
}

.program-logo-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.program-logo {
  padding: 10px 30px;
}

.program-title {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 24px;
  color: #023353;
  margin-bottom: 15px;
}

.program-description {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  text-align: justify;
}

.program-button {
  background: #023353;
  border-radius: 30px;
  height: 40px;
  width: 200px;
  margin: 0 auto 0;
  cursor: pointer;
  color: #fff;
  text-decoration: none;
  font-family: "Poppins", sans-serif;
  font-size: 13px;
  outline: none;
  border: 0;
}

.program-button-link:link,
.program-button-link:visited,
.program-button-link:active {
  color: #fff;
  text-decoration: none;
  font-family: "Poppins", sans-serif;
  font-size: 13px;
}

.sponser-section {
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.sponser-title {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 15px;
  color: #5c5c5c;
}

.archis-logo {
  width: 150px;
}

.program-button-link {
  display: inline-block;
  width: 100%;
  line-height: 40px;
}
