.start-ups-container {
  width: 100%;
}

.back-icon {
  cursor: pointer;
  margin: 0 auto 0 10px;
}

.start-up-details {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 15px;
}

.start-up-wrapper {
  height: 100px;
  display: flex;
  align-items: center;
  padding: 10px 15px;
  cursor: pointer;
}

.start-up-logo-wrapper {
  height: 100%;
  width: 100px;
}

.start-up-logo {
  width: 100%;
  padding: 10px;
}
.sub-title {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 15px;
  color: #023353;
  margin-bottom: 15px;
}
.form-page-section {
  padding: 60px 40px;
}

.title {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 16px;
  color: #023353;
}

.form-inputs {
  margin-top: 10px;
}

.form-check-box {
  margin-left: 10px;
}

.form-input {
  width: 100%;
  border-radius: 5px;
  border: 1px solid;
  margin-top: 10px;
  font-family: "Ubuntu", sans-serif;
  font-size: 14px;
  padding: 0 10px;
}
.form-input--short-text {
  height: 40px;
}

.form-label {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 15px;
  color: #023353;
}
.form-label-checkbox {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 15px;
  color: #5c5c5c;
}
.form-inputs-checkbox {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

.form-input--send-button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  background-color: #023353;
  color: #fff;
  font-weight: 700;
  cursor: pointer;
}

.form-complete-dialog-box {
  height: 100%;
  width: 100%;
  z-index: 20;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  font-family: "Poppins", sans-serif;
  text-align: center;
  color: #023353;
}

.form-complete-dialog-box .success-text {
  max-width: 300px;
  font-weight: 600;
  margin-bottom: 25px;
}

.form-complete-dialog-box .return-to-portfolio-text {
  font-size: 26px;
  font-weight: 500;
  margin-top: 65px;
}

.form-complete-dialog-box .go-back-button {
  width: 175px;
  height: 30px;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  border-radius: 10px;
  margin-top: 20px;
  border: none;
}

.form-complete-dialog-box .go-back-button:hover {
  cursor: pointer;
}
