.start-ups-container {
  width: 100%;
}

.start-up-container {
  display: flex;
  gap: 5px;
  align-items: center;
  width: 100%;
  background: #f4f4f4;
  border: 1px solid #fff;
}

.start-up-title-container {
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background: #023353;
}

.add-startup-button {
  margin-right: 20px;
}

.start-up-title {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 24px;
  color: #fff;
  text-align: center;
  width: 85%;
}

.back-icon {
  cursor: pointer;
  margin: 0 auto 0 10px;
}

.start-up-wrapper .start-up-details {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
}

.start-up-container .icons-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  height: 100%;
}

.start-up-wrapper {
  height: 100px;
  display: flex;
  align-items: center;
  padding: 10px 15px;
  cursor: pointer;

  text-decoration: none;
  width: 100%;
}

.start-up-logo-wrapper {
  height: 100%;
  width: 100px;
  background: #fff;
  border-radius: 10px;
  display: flex;
  align-items: center;
}

.arrow-icon {
  margin-left: auto;
}

.start-up-logo {
  width: 100%;
  padding: 10px;
}

.start-up-name {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 15px;
  color: #5c5c5c;
  width: 100%;
}

.start-up-category {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 12px;
  color: #5c5c5c;
  width: 100%;
}
