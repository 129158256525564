.scroll-up-icon-wrapper {
  position: fixed;
  bottom: 30px;
  right: 20px;
  background: #fff;
  border-radius: 50%;
  height: 45px;
  width: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  transform: rotate(90deg);
  border: 2px solid #023353;
  z-index: 10;
  cursor: pointer;
}