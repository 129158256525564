* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.app {
  min-height: 100vh;
  display: flex;
}
