.feedback-container {
  width: 100%;
}

.feedback-title-container {
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background: #023353;
}

.feedback-title {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 24px;
  color: #fff;
  text-align: center;
  width: 85%;
}
