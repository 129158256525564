.schedule-container {
  width: 100%;
}

.schedule-title-container {
  width: 100%;
  height: 140px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #023353;
}

.schedule-page-title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 16px;
  color: #fff;
  text-align: center;
  width: 80%;
  display: flex;
  justify-content: center;
  margin-left: 40px;
}

.scheduled-time {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 13px;
  color: #023353;
}

.menu-icon {
  cursor: pointer;
  position: absolute;
  top: 20px;
  left: 20px;
}

.start-up-details {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 15px;
}

.start-up-wrapper {
  height: 100px;
  display: flex;
  align-items: center;
  padding: 10px 15px;
  cursor: pointer;
}

.start-up-logo-wrapper {
  height: 100%;
  width: 100px;
}

.start-up-logo {
  width: 100%;
  padding: 10px;
}

.schedule-group {
  display: flex;
}

.schedule-content {
  padding: 20px;
}

.schedule-title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: #023353;
}

.schedule-sub-text {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 10px;
  color: #3f3f3f;
  white-space: pre-line;
}

.schedule-section {
  display: flex;
  flex-direction: column;
}

.time-line-section {
  border-right: 1px solid #023353;
  padding: 10px;
  min-width: 100px;
}

.time-line-detailed-section {
  padding: 10px 10px 5px 10px;
}

.schedule-detailed-section {
  padding: 5px;
}

.startup-button {
  height: 40px;
  width: 100%;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin: 30px 0;
  cursor: pointer;
  background: #fff;
  background-color: #023353;
  border: 0;
}

.startup-button-link {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 700;
  text-decoration: none;
  color: #fff;
  display: inline-block;
  width: 100%;
  line-height: 40px;
}
