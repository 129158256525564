.feedback-data-container {
  width: 100%;
}

.feedback-data-container .feedback-page-section {
  padding: 60px 40px;
}

.feedback-data-container .feedback-form-inputs {
  margin-top: 10px;
}

.feedback-form-label {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 15px;
  color: #023353;
}

.feedback-form-input {
  width: 100%;
  border-radius: 5px;
  border: 1px solid;
  margin-top: 10px;
  font-family: "Ubuntu", sans-serif;
  font-size: 14px;
  padding: 10px;
}

.feedback-input--short-text {
  height: 40px;
}

.feedback-inputs-checkbox {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

.feedback-label-checkbox {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 15px;
  color: #5c5c5c;
}

.feedback-check-box {
  margin-left: 10px;
}

.feedback-input--send-button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  background-color: #023353;
  color: #fff;
  font-weight: 700;
  cursor: pointer;
}
